export default {
  phone: "برجاء ادخال رقم هاتف صحيح",
  fullname: "برجاء ادخال اسم مكون من كلمتين على الأقل",
  name: "برجاء ادخال اسم صحيح",
  email: "برجاء ادخال بريد الكتروني صحيح",
  extensyaempid: "برجاء ادخال رقم الموظف بشكل صحيح",
  select: "برجاء اختيار احد الإختيارات المتاحة",
  checkbox: "برجاء تأكيد الاختيار",
  number: "برجاء ادخال رقم صحيح",
  required: "هذا الحقل مطلوب",
  null: "هذا الحقل مطلوب",
};
